/**
 * Converts a given name into its initials.
 * The initials are derived from the first letter of each word in the name,
 * and only the first two initials are returned.
 *
 * @param name - The name to convert to initials. If not provided, the function returns an empty string.
 * @returns The initials derived from the given name, limited to the first two characters.
 */
export function nameToInitials(name?: string): string {
  const n = name?.split(" ");
  let s = "";
  if (n) {
    for (const word of n) {
      if (word[0]) s += word[0].toUpperCase();
    }
  }
  return s.slice(0, 2);
}


  /**
 * Capitalizes the first character of the given string.
 *
 * @param str - The string to be capitalized.
 * @returns The string with the first character in uppercase.
 */
export const capitalize = (str: string) => {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};


  /**
 * Truncates the given text to a specified length and appends an ellipsis ("...") at the end.
 *
 * @param {string} text - The text to be truncated.
 * @param {number} [to=10] - The length to which the text should be truncated. Defaults to 10 if not provided.
 * @returns {string} - The truncated text with an ellipsis appended.
 */
export const truncateText = (text: string, to = 10): string => {
  return text?.slice(0, to) + "...";
};


  /**
 * Replaces all underscores in the given string with spaces.
 *
 * @param {string} value - The string from which underscores will be removed.
 * @returns {string} - A new string with all underscores replaced by spaces.
 */
export const removeUnderScore = (value: string): string => {
  return value?.replace(/_/g, " ") ?? "";
};



  /**
 * Validates an email address using a regular expression.
 * The regular expression checks for a basic email format with alphanumeric characters, dots, underscores, and hyphens in the local part,
 * and domain parts consisting of alphanumeric characters separated by dots.
 *
 * @param {string} email - The email address to be validated.
 * @returns {RegExpExecArray | null} - Returns an array with match details if the email is valid, otherwise `null`.
 */
export const validateEmail = (email: string): RegExpExecArray | null => {
  if (email) {
    return RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/).exec(
      email.toLowerCase()
    );
  }
  return null; // Return null if email is falsy
};


  /**
 * Checks if the given phone number contains any letters.
 *
 * @param {string} phoneNumber - The phone number to be checked for letters.
 * @returns {boolean} - Returns `true` if the phone number contains letters, otherwise `false`.
 */
  export function containsLetter(phoneNumber: string):boolean {
    if (phoneNumber) {
      return /[a-zA-Z]/.test(phoneNumber);
    }

    return false;
  }

  /**
 * Checks if the given text contains any special characters.
 * Special characters are defined as any character that is not a letter, digit, or whitespace.
 *
 * @param {string} text - The text to be checked for special characters.
 * @returns {boolean} - Returns `true` if special characters are found, otherwise `false`.
 */
  export function containsSpecialCharacter(text: string): boolean {
    if (text) {
      // Regular expression to check for special characters
      return /[^a-zA-Z0-9\s]/.test(text);
    }
    return false; // Return false if text is falsy
  }
  

  export function getSpecializationName(name: string): string {
    const specName = name.split("#")[1];
    return specName;
  }

  /**
 * Checks whether the input string consists of a single digit repeated throughout.
 *
 * @param input The string input to be checked. It should be a numeric string.
 * @returns `true` if the input consists of the same digit repeated; otherwise, `false`.
 */
  export const checkRepeatedNumber = (input: string) => {
    // Ensure the input is a valid string of digits
    if (!/^\d+$/.test(input)) {
        return false;
    }

    // Extract the first digit
    const firstDigit = input[0];

    // Create a regex pattern to match repeated digits
    const repeatedPattern = new RegExp(`^${firstDigit}+$`);

    // Check if the input matches the pattern
    return input.length > 1 && repeatedPattern.test(input);
};


/**
 * Checks if the input string contains any characters other than English letters, numbers, symbols, spaces, 
 * or German characters (e.g., ä, ö, ü, ß).
 * Disallows characters from other non-English languages (e.g., Chinese, Hindi, Arabic, etc.).
 *
 * @param {string} input - The input string to be checked.
 * @returns {boolean} - Returns `true` if the input contains disallowed characters, `false` if only English letters, 
 * numbers, symbols, spaces, and German characters are present.
 *
 * @example
 * // Returns false (only English letters, numbers, and symbols)
 * containsNonEnglishCharacters("Hello123!@#");
 *
 * @example
 * // Returns true (contains non-English characters)
 * containsNonEnglishCharacters("Hello 世界");
 *
 * @example
 * // Returns false (contains English and allowed German characters)
 * containsNonEnglishCharacters("Hallo äöüß");
 *
 * @example
 * // Returns true (contains disallowed non-English characters)
 * containsNonEnglishCharacters("Bonjour 世界");
 */

export const containsNonEnglishCharacters = (input:string):boolean=>{ 
  const nonEnglishPattern = /[^\x20-\x7EäöüÄÖÜß]/;
  return nonEnglishPattern.test(input);
};


/**
 * Formats a string by performing the following:
 * 1. Removes the word 'Changes' from the string.
 * 2. Inserts a space before each uppercase letter (except the first letter).
 * 3. Capitalizes the first letter of the resulting string.
 *
 * @param {string} field - The string to format.
 * @returns {string} - The formatted string with proper spacing and capitalization.
 *
 * @example
 * // Removes 'Changes', adds space before uppercase, and capitalizes first letter
 * formatField('someChangesFieldName');
 * // Returns: 'Some field name'
 */
export const formatField = (field: string) => {
  // Replace 'Changes' with an empty string
  const withoutChanges = field.replace(/Changes/g, "");

  // Add a space before each uppercase letter, except the first letter
  const formattedField = withoutChanges.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize the first letter
  return formattedField.charAt(0).toUpperCase() + formattedField.slice(1);
};
