import resourceManagerRoutes from "@/apps/resource-manager/router/resourceManagerRoutes";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import PageNotFound from "@/apps/PageNotFound.vue";
import { isLoggedIn, logout } from "../services/auth";

const env = process.env;
const route: Array<RouteRecordRaw> = [
  {
    path: "/logout",
    name: "logout",
    alias: "/logout",
    component: () => "",
    meta: { auth: false },
    beforeEnter: (_to, _from, next) => {
      logout();
      next("/login");
    }
  },

  {
    path: "/login",
    name: "login",
    component: () => (window.location.href = `${env.VUE_APP_MAIN_PAGE_URL}login`),
    meta: { auth: false }
  },
  {
    path: "/",
    name: "dashboard",
    alias: "/dashboard",
    component: () =>
      import(
        /* webpackChunkName: "sign-in" */ "../apps/resource-manager/components/ResourceManagementDashoard.vue"
      ),
    meta: { auth: false }
  },

  {
    path: "/myglobalcomponentsarehere",
    name: "ShowGlobal",
    component: () =>
      import(/* webpackChunkName: "global" */ "@/ui-kit/GlobalComponents.vue")
  },


  {
    path: "/:catchAll(.*)*",
    name: "PageNotFound",
    component: PageNotFound,
    meta: { auth: true }
  }

  /** End of Auth routes */
];

export const routes = route.concat(resourceManagerRoutes);

// Create the router
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, _from, next) => {
  if (to.meta.auth && to.query["app-token"]) {
    next();
  } else if (to.meta.auth && !isLoggedIn()) {
    const redirect = `${window.location.protocol}//${window.location.host}`;
    window.location.href = `${process.env.VUE_APP_SSO}/login?redirect-to=${redirect}`;
  } else {
    next();
  }
});

export default router;
