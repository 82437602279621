// windowWidth.js

import { ref, onMounted, onBeforeUnmount } from "vue";

export default function useWindowWidth() {
  const windowWidth = ref(window.innerWidth);

  const handleResize = () => {
    windowWidth.value = window.innerWidth;
  };

  onMounted(() => {
    window.addEventListener("resize", handleResize);
  });

  onBeforeUnmount(() => {
    window.removeEventListener("resize", handleResize);
  });

  return { windowWidth };
}
